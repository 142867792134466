import React, { useState, useEffect } from "react"
import Page from '../components/Page'
import Layout from "../components/Layout"
import useStoryblok from '../utils/storyblok'
import { PageContext } from 'src/utils/context'
import SetCookie from 'src/utils/SetCookie'
import GetCookie from "../utils/GetCookie"
 
export default function PageTemplate({ pageContext, location }) {
    const story = useStoryblok(pageContext.story, location)
    const breadcrumbs = useStoryblok(pageContext.breadcrumb, location)
    const first_published_at = useStoryblok(pageContext.story.first_published_at, location)
    const [ctaReferrer, setCtaReferrer] = useState(null)
    const [gatedContentAllowed, setGatedContentAllowed] = useState(false)

    const getGatedContentAllowed = () => {
      return document.cookie.replace(/(?:(?:^|.*;\s*)gated_content_allowed\s*\s*([^;]*).*$)|^.*$/, "$1") ? true : false;
    }

    const getCtaReferrer = () => {
      return GetCookie("cta_referrer");
    }  

    const onSetGatedContentAllowed = (value) => {
      SetCookie(`gated_content_allowed=${value}`);
      setGatedContentAllowed(value);
    }

    const onSetCtaReferrer = (value) => {
      SetCookie(`cta_referrer=${value}`);
      setCtaReferrer(value);
    }

    useEffect(() => {
        if ( getGatedContentAllowed() ) {
            onSetGatedContentAllowed(getGatedContentAllowed())
        }
        if( getCtaReferrer() ) {
          onSetCtaReferrer(getCtaReferrer())
        }         
    }, []);
 
    return (
      <PageContext.Provider 
        value={{ 
          button_override_title: story.content.button_override_title,
          button_override_url: story.content.button_override_url,
          gatedContentAllowed: gatedContentAllowed,
          setGatedContentAllowed: onSetGatedContentAllowed,
          name: story.name,
          setCtaReferrer: onSetCtaReferrer,
          ctaReferrer: ctaReferrer
        }}
      > 
        <Layout location={location} postInfo={story}>
          <Page
            blok={{
              ...story.content,
              breadcrumbs: breadcrumbs,
              first_published_at: first_published_at,
              name: story?.name,
            }}
          />
        </Layout>
      </PageContext.Provider>     
    )
}